/** @jsx jsx */
import { jsx } from "theme-ui"
import { LinkButton } from "gatsby-interface"
import { graphql, Link } from "gatsby"
import { SkipNavTarget } from "@modules/a11y"
import Container from "../../components/container"
import Pullquote from "../../components/blocks/pullquote"
import Footer from "../../components/footer"
import { FooterNewsletter } from "../../components/PageFooter/FooterNewsletter"
import Header from "../../components/header"
import HeroContainer from "../../components/containers/hero"
import Layout from "../../layouts"
import { titleStyles } from "../../utils/styles"
import PageMetadata from "../../components/page-metadata"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import { MdArrowForward as ArrowForwardIcon } from "react-icons/md"
import { FeaturedSitesGrid } from "../showcase/featured-sites"

import BlogPost from "../../components/use-cases/blog-post"
import ValueProp from "../../components/use-cases/value-prop"
import triangle from "!raw-loader!svgo-loader!../../assets/use-case-polygon.svg"
import ellipse from "!raw-loader!svgo-loader!../../assets/use-case-ellipse.svg"
import ellipse2 from "!raw-loader!svgo-loader!../../assets/use-case-ellipse-2.svg"
import rectangle from "!raw-loader!svgo-loader!../../assets/use-case-rectangle.svg"
import SVGInline from "react-svg-inline"

import { Button } from "../../components/core/button"

const buttonRowStyles = theme => ({
  maxWidth: `100%`,
  display: `flex`,
  marginBottom: theme.space[4],
  marginLeft: `auto`,
  marginRight: `auto`,
  flexDirection: `column`,

  [theme.mediaQueries.phablet]: {
    flexDirection: `row`,
  },

  a: {
    textDecoration: `none`,
    color: `unset`,
  },
})

const stepButtonStyles = {
  position: `relative`,
  border: `none`,
  borderTop: t => [`none`, null, `1px solid ${t.colors.blackFade[10]}`],
  marginTop: [4, null, 0],
  background: `transparent`,
  textAlign: `left`,
  paddingTop: 1,
  cursor: `pointer`,
  flex: 1,

  "&:after": {
    content: `""`,
    height: 2,
    position: `absolute`,
    top: -1,
    left: 0,
    width: `100%`,
    background: `lilac`,
    opacity: 0,
    transition: `opacity 250ms`,
  },

  "&:hover": {
    color: `purple.60`,
    borderTop: t => `1px solid ${t.colors.purple[60]}`,
  },
}

const activeStepButtonStyles = {
  ...stepButtonStyles,
  color: `purple.60`,

  "&&:after": {
    opacity: 1,
  },
}

const StepButton = ({ num, title, slug, isSelected }) => {
  return (
    <Link
      to={`#${slug}`}
      sx={isSelected ? activeStepButtonStyles : stepButtonStyles}
    >
      <div sx={{ display: `inline-block`, textAlign: `left` }}>
        {String(num).padStart(2, 0)}
        <br />
        <strong>{title}</strong>
      </div>
    </Link>
  )
}

const styles = {
  constrainWidth: {
    maxWidth: 1200,
    margin: `auto`,
  },
  topDescription: {
    color: `text.secondary`,
    fontFamily: `heading`,
  },
  sectionDescription: {
    maxWidth: 650,
  },
  sectionDescriptionLarge: {
    fontSize: 4,
    maxWidth: 650,
  },
  valuePropGridTop: theme => ({
    [theme.mediaQueries.phablet]: {
      gridTemplateColumns: `repeat(2, 1fr)`,
      padding: 4,
    },
    [theme.mediaQueries.tablet]: {
      gridRowGap: 1,
      gridColumnGap: 9,
      padding: 6,
    },
  }),
  valuePropGrid: theme => ({
    display: `grid`,
    gridTemplateColumns: `repeat(1, 1fr)`,
    gridRowGap: 1,
    gridColumnGap: 9,
    [theme.mediaQueries.phablet]: {
      gridTemplateColumns: `repeat(2, 1fr)`,
    },
    [theme.mediaQueries.tablet]: {
      gridRowGap: 1,
      gridColumnGap: 9,
    },
    marginTop: 9,
    marginBottom: 1,
  }),
  valuePropGridOverrides: theme => ({
    0: {},
    // one-column
    1: {
      [theme.mediaQueries.phablet]: {
        gridTemplateColumns: `repeat(1, 1fr)`,
        maxWidth: 500,
      },
    },
    // two-column
    2: {},
    // three-column
    3: {
      [theme.mediaQueries.desktop]: {
        gridTemplateColumns: `repeat(3, 1fr)`,
      },
    },
    // two-column
    4: {},
    // three-column
    5: {
      [theme.mediaQueries.desktop]: {
        gridTemplateColumns: `repeat(3, 1fr)`,
      },
    },
  }),
  postsGrid: theme => ({
    pb: 8,
    display: `grid`,
    gridTemplateColumns: `repeat(1, 1fr)`,
    [theme.mediaQueries.phablet]: {
      gridTemplateColumns: `repeat(2, 1fr)`,
    },
    gridGap: 8,
  }),
  postsGridOverrides: theme => ({
    0: {},
    // one-column
    1: {},
    // two-column
    2: {},
    // three-column
    3: {
      [theme.mediaQueries.desktop]: {
        gridTemplateColumns: `repeat(3, 1fr)`,
      },
    },
  }),
  ctaGrid: theme => ({
    display: `grid`,
    gridTemplateColumns: `repeat(1, 1fr)`,
    gridRowGap: 7,
    [theme.mediaQueries.phablet]: {
      gridTemplateColumns: `repeat(2, 1fr)`,
    },
    [theme.mediaQueries.tablet]: {
      gridTemplateColumns: `repeat(3, 1fr)`,
    },
    margin: `auto`,
    marginTop: 9,
  }),
  firstCta: theme => ({
    textAlign: `center`,
    minWidth: 220,
    [theme.mediaQueries.phablet]: {
      borderRight: `1px solid ${theme.colors.blackFade[10]}`,
    },
  }),
  secondCta: theme => ({
    textAlign: `center`,
    minWidth: 180,
    [theme.mediaQueries.tablet]: {
      borderRight: `1px solid ${theme.colors.blackFade[10]}`,
    },
  }),
  thirdCta: {
    textAlign: `center`,
    "& > div": {
      margin: `auto`,
      justifyContent: `center`,
    },
    "& > div > form": {
      marginTop: 0,
    },
    "& > div > form > div > div > input": {
      height: `2.25rem`,
    },
    "& > div > form > button": {
      height: `2.25rem`,
      width: `2.25rem`,
    },
  },
}

const SectionShapes = ({ index }) =>
  index === 0 ? (
    <div>
      <div
        sx={theme => ({
          position: `absolute`,
          left: -25,
          top: 175,
          display: `none`,
          [theme.mediaQueries.phablet]: {
            display: `initial`,
            left: -75,
          },
          [theme.mediaQueries.desktop]: {
            left: -25,
          },
        })}
      >
        <SVGInline svg={ellipse} />
      </div>
      <div
        sx={theme => ({
          position: `absolute`,
          right: -25,
          bottom: -50,
          display: `none`,
          [theme.mediaQueries.phablet]: {
            display: `initial`,
            right: -75,
          },
          [theme.mediaQueries.desktop]: {
            right: -25,
          },
        })}
      >
        <SVGInline svg={rectangle} />
      </div>
    </div>
  ) : index === 1 ? (
    <div
      sx={theme => ({
        position: `absolute`,
        left: -25,
        top: 275,
        display: `none`,
        [theme.mediaQueries.phablet]: {
          display: `initial`,
          right: -75,
        },
        [theme.mediaQueries.desktop]: {
          right: -25,
        },
      })}
    >
      <SVGInline svg={ellipse2} />
    </div>
  ) : null

const SectionHeaderRow = ({ sectionHeaders, start }) =>
  sectionHeaders ? (
    <div css={buttonRowStyles}>
      {sectionHeaders.map((section, index) => (
        <StepButton
          key={index}
          num={start + index + 1}
          slug={section.slug}
          title={section.title}
        />
      ))}
    </div>
  ) : null

const Breadcrumb = ({ parentPage }) => (
  <p
    sx={{
      a: { color: `grey.70`, textDecoration: `none` },
      color: `grey.50`,
    }}
  >
    <Link
      sx={{ borderBottom: t => `1px solid ${t.colors.grey[70]}` }}
      to="/use-cases/"
    >
      Use Cases
    </Link>
    <ArrowForwardIcon
      sx={{
        display: `inline`,
        marginLeft: 2,
        marginRight: 2,
        fontSize: `95%`,
        paddingTop: 1,
      }}
    />
    By {parentPage}
  </p>
)

const PageTitle = ({ pluralTitle, description, hasPrimarySubtitle }) =>
  hasPrimarySubtitle ? (
    <h1 css={t => [titleStyles, { color: t.colors.purple[60] }, {}]}>
      {`Gatsby for ${pluralTitle}: `}
      <div
        sx={{
          display: `inline`,
          p: { display: `inline` },
          color: `black`,
        }}
      >
        {renderRichText(description)}
      </div>
    </h1>
  ) : (
    <div>
      <h1 css={[titleStyles, { color: false }, {}]}>
        {`Gatsby for ${pluralTitle}`}
      </h1>
      <h2 sx={styles.topDescription}>{renderRichText(description)}</h2>
    </div>
  )

const UseCaseContentBlock = ({
  top,
  valueProps,
  examples,
  pullquote,
  blogposts,
}) => (
  <div>
    {valueProps && (
      <div
        sx={t => ({
          ...styles.valuePropGrid(t),
          ...(top ? styles.valuePropGridTop(t) : {}),
          ...styles.valuePropGridOverrides(t)[
            valueProps ? valueProps.length : 0
          ],
        })}
      >
        {valueProps.map((valueProp, i) => (
          <ValueProp key={i} valueProp={valueProp} />
        ))}
      </div>
    )}
    {!!examples.length && (
      <div sx={{ "& > * > * ": { borderBottom: `none !important` } }}>
        <FeaturedSitesGrid featured={examples} hideShowcaseLink={true} />
      </div>
    )}
    {pullquote.pullquoteText ? (
      <div sx={{ maxWidth: 800, margin: `auto` }}>
        <Pullquote citation={pullquote.pullquoteCitation}>
          {renderRichText(pullquote.pullquoteText)}
        </Pullquote>
      </div>
    ) : null}
    {blogposts && (
      <div>
        {!top && !!blogposts.length && (
          <h4
            sx={{
              textTransform: `uppercase`,
              color: `grey.50`,
              letterSpacing: `0.05em`,
            }}
          >
            Case Studies & Articles
          </h4>
        )}
        <div
          sx={t => ({
            ...styles.postsGrid(t),
            ...styles.postsGridOverrides(t)[blogposts.length],
          })}
        >
          {blogposts.map(post => (
            <BlogPost key={post.id} post={post} />
          ))}
        </div>
      </div>
    )}
  </div>
)

const UseCaseLandingPage = ({
  data: {
    contentfulUseCaseLandingPage,
    allContentfulUseCaseHomepage,
    sites,
    posts,
  },
}) => {
  const socialImageURL =
    allContentfulUseCaseHomepage.nodes[0].socialImage.file.url
  const page = contentfulUseCaseLandingPage
  const itemInSection = (itemToCheck, section, items) => {
    // if a section has no associated sites/posts ("items")
    // then the site/post ("itemToCheck") in question can't belong to the section
    if (!section[items]) {
      return false
    }
    // generate array of IDs corresponding to a section, remove nulls/falsy
    const IDs = section[items].map(item => item.content).filter(ID => ID)
    // check if a site/post's ID belongs in this section of the page
    return IDs.includes(itemToCheck.databaseId)
  }

  // for checking if blog post belongs at top level of page
  // (as opposed to a specific section)
  const itemInPage = (itemToCheck, items) => {
    if (!items) {
      return false
    }
    const IDs = items.map(item => item.content).filter(ID => ID)
    return IDs.includes(itemToCheck.databaseId)
  }

  const sectionHeaderRows = [[], []]
  if (page.sections?.length > 4) {
    sectionHeaderRows[0] = page.sections.slice(0, 3)
    sectionHeaderRows[1] = page.sections.slice(3)
  } else {
    sectionHeaderRows[0] = page.sections
  }

  const SEOPageDescription = `${documentToPlainTextString(
    page.description?.raw ? JSON.parse(page.description.raw) : null
  )}${
    page?.modularValueProps?.length
      ? ` ${page.modularValueProps
          .map(valueProp => valueProp.title)
          .join(". ")}.`
      : ``
  }`

  return (
    <Layout>
      <PageMetadata
        title={`Gatsby for ` + page.pluralTitle}
        description={SEOPageDescription}
        image={{
          contentfulSrc: socialImageURL,
          width: 1600,
          height: 836,
        }}
        twitterCard={`summary_large_image`}
      />
      <Header />
      <div
        sx={theme => ({
          position: `absolute`,
          right: -25,
          top: 175,
          display: `none`,
          [theme.mediaQueries.phablet]: {
            display: `initial`,
            right: -75,
          },
          [theme.mediaQueries.desktop]: {
            right: -25,
          },
        })}
      >
        <SVGInline svg={triangle} />
      </div>
      <main>
        <SkipNavTarget />
        <HeroContainer sx={{ textAlign: `center`, ...styles.constrainWidth }}>
          <Container>
            <Breadcrumb parentPage={page.contentfulparent?.title} />
            <PageTitle
              pluralTitle={page.pluralTitle}
              description={page.description}
              hasPrimarySubtitle={page.sections?.length}
            />
            <Button to="/get-started/" buttonStyle="secondary">
              Get Started With Gatsby
            </Button>
            <UseCaseContentBlock
              top
              valueProps={page.modularValueProps}
              examples={sites.nodes.filter(site =>
                itemInPage(site, page.examples)
              )}
              pullquote={{
                pullquoteText: page.pullquoteText,
                pullquoteCitation: page.pullquoteCitation,
              }}
              blogposts={posts.nodes?.filter(post =>
                itemInPage(post, page.blogPosts)
              )}
            />
            {sectionHeaderRows.map((row, i) => (
              <SectionHeaderRow sectionHeaders={row} start={3 * i} key={i} />
            ))}
            <br />
          </Container>
        </HeroContainer>
        {page.sections &&
          page.sections.map((section, i) => (
            <section
              key={section.id}
              sx={{
                pb: 7,
                pt: 10,
                background: t => (i % 2 ? t.colors.purple[5] : false),
                position: `relative`,
              }}
              id={section.slug}
            >
              <SectionShapes index={i} />
              <Container sx={{ ...styles.constrainWidth }}>
                <Link
                  to={`#${section.slug}`}
                  sx={{ textDecoration: `none`, borderBottom: `0` }}
                >
                  <h2
                    css={[
                      titleStyles,
                      { textDecoration: `none`, borderBottom: `0` },
                      {},
                    ]}
                  >
                    {section.title}
                  </h2>
                </Link>
                <div
                  sx={
                    section.description &&
                    documentToPlainTextString(section.description.raw).length <
                      75
                      ? styles.sectionDescriptionLarge
                      : styles.sectionDescription
                  }
                >
                  {section.description && renderRichText(section.description)}
                </div>
                <UseCaseContentBlock
                  valueProps={section.valueProps}
                  examples={sites.nodes.filter(site =>
                    itemInSection(site, section, "examples")
                  )}
                  pullquote={{
                    pullquoteText: section.pullquoteText,
                    pullquoteCitation: section.pullquoteCitation,
                  }}
                  blogposts={posts.nodes.filter(post =>
                    itemInSection(post, section, "blogPosts")
                  )}
                />
              </Container>
            </section>
          ))}
        <Container>
          <div sx={styles.ctaGrid}>
            <div sx={styles.firstCta}>
              <h2>Ready to try it out?</h2>
              <LinkButton to="/get-started/" rightIcon={<ArrowForwardIcon />}>
                Get started
              </LinkButton>
            </div>
            <div sx={styles.secondCta}>
              <h2>Want to chat?</h2>
              <LinkButton to="/contact-us/">Contact us</LinkButton>
            </div>
            <div sx={styles.thirdCta}>
              <h2>Stay in the loop</h2>
              <FooterNewsletter hideLabel />
            </div>
          </div>
        </Container>
      </main>
      <Footer />
    </Layout>
  )
}

export default UseCaseLandingPage

export const pageQuery = graphql`
  query UseCaseLandingPageQuery(
    $id: String!
    $showcaseIDs: [Int!]
    $postIDs: [Int!]
  ) {
    contentfulUseCaseLandingPage(id: { eq: $id }) {
      title
      pluralTitle
      contentfulparent {
        title
      }
      description {
        raw
      }
      pullquoteText {
        raw
      }
      pullquoteCitation
      modularValueProps {
        id
        title
        description {
          raw
        }
        icon {
          svg {
            content # SVG content optimized with SVGO
            dataURI # Optimized SVG as compact dataURI
            absolutePath #
            relativePath #
          }
          file {
            contentType
            url
            fileName
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
      blogPosts {
        content
      }
      examples {
        content
      }
      sections {
        id
        title
        slug
        description {
          raw
        }
        pullquoteText {
          raw
        }
        pullquoteCitation
        valueProps {
          title
          description {
            raw
          }
          icon {
            svg {
              content # SVG content optimized with SVGO
              dataURI # Optimized SVG as compact dataURI
              absolutePath #
              relativePath #
            }
            file {
              contentType
              url
              fileName
              details {
                image {
                  width
                  height
                }
              }
            }
          }
        }
        examples {
          content
        }
        blogPosts {
          content
        }
      }
    }
    sites: allWpShowcaseSite(filter: { databaseId: { in: $showcaseIDs } }) {
      nodes {
        id
        databaseId
        showcaseSiteFields {
          title
          mainUrl
          screenshot {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    posts: allWpPost(filter: { databaseId: { in: $postIDs } }) {
      nodes {
        id
        databaseId
        title
        useCasesInfo {
          shortTitle
          caseStudyLogo {
            localFile {
              childImageSharp {
                fixed(height: 50) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        date(formatString: "MMMM Do, YYYY")
        slug
        rawExcerpt {
          text
        }
        author {
          node {
            name
            slug
            authorInfo {
              formalTitle
              bio
              avatar {
                localFile {
                  childImageSharp {
                    resize {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allContentfulUseCaseHomepage {
      nodes {
        socialImage {
          file {
            url
          }
        }
      }
    }
  }
`
