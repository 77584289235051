import React from "react"

export const QuotationMarkOrnament = ({ width = 25, height = 20, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    focusable="false"
    {...rest}
  >
    <path d="M7.57 19.4c-.467 0-1.097-.163-1.89-.49-1.727-1.027-3.033-2.17-3.92-3.43C.92 14.173.5 12.633.5 10.86.5 9.553.873 8.2 1.62 6.8c.747-1.4 1.89-2.66 3.43-3.78C6.59 1.853 8.503 1.013 10.79.5v1.54c-1.633.42-3.057 1.12-4.27 2.1-1.167.98-1.75 2.147-1.75 3.5 0 .84.21 1.633.63 2.38.467.747 1.423 1.517 2.87 2.31l2.45 1.26.07.84-1.54 3.43c-.373 1.027-.933 1.54-1.68 1.54zm13.93 0c-.467 0-1.097-.163-1.89-.49-1.727-1.027-3.033-2.17-3.92-3.43-.84-1.307-1.26-2.847-1.26-4.62 0-1.307.373-2.66 1.12-4.06.747-1.4 1.89-2.66 3.43-3.78 1.54-1.167 3.453-2.007 5.74-2.52v1.54c-1.633.42-3.057 1.12-4.27 2.1-1.167.98-1.75 2.147-1.75 3.5 0 .84.21 1.633.63 2.38.467.747 1.423 1.517 2.87 2.31l2.45 1.26.07.84-1.54 3.43c-.373 1.027-.933 1.54-1.68 1.54z" />
  </svg>
)
