/** @jsx jsx */
import { jsx } from "theme-ui"
import { StarOrnament as TempIcon } from "../../assets/ornaments/StarOrnament"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const SVGImage = ({ svg, file }) =>
  svg && svg.content && file.contentType === "image/svg+xml" ? (
    <span
      sx={{ svg: { height: 48 } }}
      dangerouslySetInnerHTML={{ __html: svg.content }}
    />
  ) : null

const styles = {
  title: {
    mt: 0,
    mb: 3,
  },
  icon: {
    fill: t => t.colors.lilac,
    marginRight: 3,
    verticalAlign: `middle`,
    width: 6,
  },
  text: {
    verticalAlign: `middle`,
  },
}

const ValueProp = ({ valueProp }) => {
  return (
    <div>
      {valueProp.icon ? (
        <SVGImage
          svg={valueProp.icon.svg}
          fluid={valueProp.icon.fluid}
          file={valueProp.icon.file}
        />
      ) : (
        <TempIcon sx={styles.icon} />
      )}
      <h3 sx={styles.title}>
        <span sx={styles.text}>{valueProp.title}</span>
      </h3>
      {renderRichText(valueProp.description)}
    </div>
  )
}

export default ValueProp
